import React from 'react';
import {
  Icon,
  Image,
  MenuButton,
  MenuList,
  Stack,
  Menu as ChakraMenu,
  IconButton,
} from '@chakra-ui/react';
import { BiDotsVerticalRounded, BiEditAlt } from 'react-icons/bi';
import Breadcrumb from '../breadcrumb';
import Title from '../title';
import Button from '../button';
import StoreIcon from '../../../../assets/icons/miniStore.svg';

import * as S from './styles';
import * as I from './interfaces';

const GenericForm = ({
  title,
  quantity,
  breadcrumb,
  children,
  showBackButton,
  backButtonUrl,
  handleAction,
  actionText,
  buttonIcon = BiEditAlt,
  isDisabled,
  secondaryActions,
  menuItem,
  copyText,
}: I.GenericFormType) => {
  return (
    <S.Container>
      <Stack spacing="medium" py="2">
        <Breadcrumb items={breadcrumb} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title
            showBackButton={showBackButton}
            backButtonUrl={backButtonUrl}
            text={title}
            quantity={quantity}
            data-testid="title-item"
            {...(copyText && { copy: { text: copyText, color: 'black' } })}
          />
          <Stack direction="row" spacing="medium">
            {secondaryActions && isDisabled && (
              <>
                {secondaryActions.map((item, index) => {
                  const key = `button-secondaryAction-${index}`;
                  return (
                    <Button
                      key={key}
                      onClick={item.action}
                      variant="ghost"
                      leftIcon={item?.icon || <Image src={StoreIcon} />}
                    >
                      {item?.text}
                    </Button>
                  );
                })}
              </>
            )}
            {handleAction && isDisabled && (
              <Button
                onClick={handleAction}
                colorScheme="v2.brand"
                leftIcon={<Icon as={buttonIcon} />}
              >
                {actionText}
              </Button>
            )}
            {menuItem && (
              <ChakraMenu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={BiDotsVerticalRounded} />}
                  variant="ghost"
                />
                <MenuList>{menuItem}</MenuList>
              </ChakraMenu>
            )}
          </Stack>
        </Stack>
        {children}
      </Stack>
    </S.Container>
  );
};

export default GenericForm;
