import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Switch, FormControl, FormLabel } from '@chakra-ui/react';

import * as I from './interfaces';

const InputSwitch: ForwardRefRenderFunction<
  HTMLInputElement,
  I.InputSwitchProps
> = ({ formcontrol, label, name, ...rest }, ref) => {
  return (
    <FormControl {...formcontrol}>
      <FormLabel htmlFor={name} mt={rest.mt}>
        {label}
      </FormLabel>
      <Switch
        ref={ref}
        id={name}
        name={name}
        colorScheme="v2.brand"
        variant="boxy"
        isDisabled={formcontrol.isDisabled}
        {...rest}
      />
    </FormControl>
  );
};

export default forwardRef(InputSwitch);
