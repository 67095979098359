import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import DoubleCheckModal from '../doubleCheckModal';
import GenericFooter from '../../../complex/form/generic/footer';

import * as I from './interface';

export const FormFooter: React.FC<I.FooterProps> = ({
  onDescartOpen,
  onDescartClose,
  isDescartOpen,
  handleDescart,
  handleSubmit,
  formName,
  label,
  id,
  isRulesFlow,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const methods = useFormContext();

  return (
    <>
      <GenericFooter
        publishNewText={`Publicar ${label}`}
        publishUpdateText="Publicar alterações"
        alertText={`Revise as informações antes de publicar o ${label}.`}
        onDescartOpen={onDescartOpen}
        onOpen={onOpen}
        hasID={Boolean(id)}
        isEditing
      />

      <DoubleCheckModal
        title={`Publicar novo ${label}`}
        description={`Ao publicar as alterações, elas serão vinculadas ao ${label}.`}
        isOpen={isOpen}
        onClose={onClose}
        modal={{ size: 'xl' }}
        showCommentField={!!(label === 'Fluxo' && isRulesFlow)}
        primaryButton={{
          colorScheme: 'v2.brand',
          text: `Publicar ${label}`,
          formName,
          action: handleSubmit,
        }}
        label={label}
        isLoading={methods.formState.isSubmitting}
      />

      <DoubleCheckModal
        title={`Descartar alterações do ${label}`}
        description="Ao descartar as alterações que você está fazendo agora,
        o formulário voltará ao estado da última atualização, eliminando todas
        as modificações que você fez na edição atual. Tem certeza que deseja prosseguir?"
        isOpen={isDescartOpen}
        onClose={onDescartClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'v2.brand',
          text: 'Descartar alterações',
          action: handleDescart,
        }}
      />
    </>
  );
};

export default FormFooter;
