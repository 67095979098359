/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { Button, Stack, Box, Text, Icon } from '@chakra-ui/react';
import { Query, QueryResult } from '@material-table/core';
import { toast } from 'react-toastify';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BiPlus } from 'react-icons/bi';
import { HiArrowRight } from 'react-icons/hi';
import * as S from './styles';
import * as I from '../../../interfaces/actions_list';

import { editOptions, createOptions } from './tableConfig';
import DefaultTable from '../../base/v2/table';
import InputText from '../../base/v2/inputs/text';

import useActionLists from '../../../hooks/api/actionLists';
import Header from '../../base/v2/formHeader';
import { clientColumns, validateSchema } from './utils';
import { H3 } from '../../base/Headings';

const ActionsListForm = ({
  initialData,
  isDisabled,
}: I.PropsActionsForm): React.ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [items, setItems] = useState<I.ActionsListItemModel[]>([]);

  const { getActionsList, postAction, createActionsList, deleteAction } =
    useActionLists();
  const { push } = useHistory();
  const tableRef: any = useRef();

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    maxFiles: 1,
    disabled: isDisabled,
    multiple: false,
    accept: ['application/json'],
  });

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: initialData ?? {},
  });

  const {
    register,
    formState: { errors },
  } = methods;

  const files = acceptedFiles.map((file) => (
    <Text as="span" fontWeight="bold" key={file.name}>
      {file.name} - {file.size} bytes
    </Text>
  ));

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
  };

  const loadItems = (
    query: Query<I.ActionsListItemModel>
  ): Promise<QueryResult<I.ActionsListItemModel>> =>
    new Promise((resolve, reject) => {
      getActionsList({ id: initialData?.id, query })
        .then((result) => {
          if (result.data.items) {
            resolve({
              data: result.data.items,
              page: result.data.page - 1,
              totalCount: result.data.total_of_items,
            });
          } else {
            resolve({
              data: [],
              page: result.data.page - 1,
              totalCount: result.data.total_of_items,
            });
          }
        })
        .catch(() => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          reject(toast.error('Nenhum resultado encontrado.'));
        });
    });

  async function bindItems(data: I.ActionsListModel): Promise<void> {
    const sendData = acceptedFiles?.[0] ? acceptedFiles[0] : items;

    postAction({ id: data.id, data: sendData, version: data.version })
      .then((res) => {
        setTimeout(() => {
          setIsSubmitting(false);
          push(`/actions-list/info?id=${data.id}`);
        }, 1000);
        if (res.request.status === 200 || res.request.status === 201) {
          toast.success('Item(s) adicionados com sucesso!');

          setTimeout(() => {
            tableRef.current?.onQueryChange();
            removeAll();
          }, 1000);
          setIsSubmitting(false);

          return;
        }
        const { reason } = JSON.parse(res.request.response);
        toast.error(`Não foi possível inserir Item(s) a lista. ${reason}`);
        setIsSubmitting(false);
      })

      .catch(() => {
        toast.error('Não foi possível inserir Item(s) a lista.');
        setIsSubmitting(false);
      });
  }

  async function createControl(data: I.ActionsListModel): Promise<void> {
    createActionsList(data)
      .then((res) => {
        if (items.length > 0 || acceptedFiles?.[0]) {
          toast.success('Lista criada com sucesso!');
          return bindItems(res.data);
        }
        toast.success('Lista criada com sucesso!');
        return push(`/actions-list/info?id=${res.data.id}`);
      })
      .catch(() => {
        toast.error('Não foi possivel criar uma lista.');
        setIsSubmitting(false);
      });
  }

  const clientEditable = {
    onRowAdd: (data: I.ActionsListItemModel): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          if (initialData) {
            const send = [data];
            return postAction({
              id: initialData?.id,
              data: send,
              version: data.version,
            })
              .then((res) => {
                if (res.request.status === 200 || res.request.status === 201) {
                  toast.success('Item adicionado com sucesso!');
                  setTimeout(() => {
                    tableRef.current?.onQueryChange();
                    return resolve();
                  }, 1000);
                  return;
                }
                const { reason } = JSON.parse(res.request.response);
                toast.error(
                  `Não foi possível inserir o item a lista. ${reason}`
                );
                setTimeout(() => {
                  tableRef.current?.onQueryChange();
                  return resolve();
                }, 1000);
              })
              .catch(() => {
                toast.error('Não foi possível inserir o item a lista.');
                setTimeout(() => {
                  tableRef.current?.onQueryChange();
                  return resolve();
                }, 1000);
              });
          }
          setItems((oldItems) => [...oldItems, data]);
          return resolve();
        }, 1000);
      }),

    onRowDelete: (data: I.ActionsListTableItemModel): Promise<void> =>
      new Promise((resolve) => {
        if (initialData) {
          return deleteAction({
            id: data.list.id,
            id2: data.id,
            version: data.version,
          })
            .then(() => {
              toast.success('Item removido com sucesso!');
              setTimeout(() => {
                tableRef.current?.onQueryChange();
                return resolve();
              }, 1000);
            })
            .catch(() => {
              toast.error('Não foi possível remover o item.');
              setTimeout(() => {
                tableRef.current?.onQueryChange();
                return resolve();
              }, 1000);
            });
        }
        const newList = [...items];
        newList.splice(data.tableData.id, 1);
        setItems(newList);
        return resolve();
      }),
  };

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  return (
    <>
      {initialData && (
        <Header
          info={initialData}
          title="Lista"
          secondItem={{
            title: 'Descrição',
            text: initialData.description,
          }}
        />
      )}
      <FormProvider {...methods}>
        <S.Container>
          <Stack spacing="md">
            <H3>Informações da lista</H3>

            {!initialData && (
              <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
                <InputText
                  label="Nome"
                  {...register('name')}
                  formcontrol={{
                    isRequired: true,
                    error: errors.name,
                    style: { width: '25%' },
                  }}
                />

                <InputText
                  label="Descrição"
                  {...register('description')}
                  formcontrol={{
                    error: errors.description,
                    style: { width: '25%' },
                  }}
                />
              </Stack>
            )}

            <Box>
              {!isDisabled && (
                <S.AddButtonContainer>
                  <Button
                    onClick={onButtonClick}
                    colorScheme="v2.brand"
                    leftIcon={<Icon as={BiPlus} />}
                  >
                    Novo Item
                  </Button>
                </S.AddButtonContainer>
              )}
              <DefaultTable
                columns={clientColumns}
                data={initialData ? loadItems : items}
                options={initialData ? editOptions : createOptions}
                editable={!isDisabled ? clientEditable : undefined}
                tableRef={tableRef}
                style={{
                  borderTop: '0.8px solid #e4e4e4c0',
                  borderBottom: '0.8px solid #e4e4e4c0',
                }}
              />
            </Box>

            <Box
              w="100%"
              bg="gray.100"
              p="25px"
              border="dotted 2px black"
              textAlign="center"
            >
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Text as="span" fontSize="sm">
                  Clique ou arraste para adicionar um arquivo JSON
                </Text>
              </div>
              <aside>{files}</aside>
            </Box>
          </Stack>
        </S.Container>
        {!isDisabled && (
          <S.ButtonsContainer>
            <Button
              type="button"
              onClick={methods.handleSubmit(async (data: any) => {
                setIsSubmitting(true);
                if (initialData) {
                  await bindItems(initialData);
                } else {
                  await createControl(data);
                }
              })}
              isDisabled={isDisabled}
              colorScheme="v2.brand"
              rightIcon={<Icon as={HiArrowRight} />}
              isLoading={isSubmitting}
            >
              {initialData ? 'Publicar Alterações' : 'Publicar Lista'}
            </Button>
          </S.ButtonsContainer>
        )}
      </FormProvider>
    </>
  );
};

export default ActionsListForm;
