import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { Query, QueryResult } from '@material-table/core';
import { toast } from 'react-toastify';
import { theme } from '../styles/theme';
import { filtersAtom } from '../components/base/v2/table/stores';

import Breadcrumb from '../components/base/v2/breadcrumb';
import DefaultTable, { fetchDataTable } from '../components/base/v2/table';
import NotificationCircle from '../components/notificationCircle';
import Button from '../components/base/v2/button';
import Title from '../components/base/v2/title';
import { OrderModel } from '../interfaces/order';
import { removeEmptyValues } from '../utils/remove_empty_values';

import * as S from '../styles/main';
import * as S2 from './styles';
import * as I from '../templates/interfaces';
import * as I2 from '../components/base/v2/table/interfaces';

export default function GenericListPage<Model extends object>({
  actionButton,
  breadcrumb,
  children,
  table,
  title,
  showBackButton,
}: I.GenericPageListProps<Model>): React.ReactElement {
  const [filters] = useAtom(filtersAtom);
  const [totalItems, setTotalItems] = useState<Record<string, number>>({});
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();

  const sendToCreate = (): void => {
    if (actionButton?.action) {
      return actionButton?.action();
    }

    return history.push(actionButton?.pushPath ?? '/');
  };

  const loadData = (
    query: Query<Model>,
    fetch: I.TableFetchApiCall<Model>,
    text: string,
    identifier: string
  ): Promise<QueryResult<Model>> => {
    const showErrorToast = () => {
      toast.error(
        `Ocorreu um erro inesperado ao buscar ${text}. Tente novamente mais tarde.`
      );
    };

    return fetchDataTable(
      query,
      fetch,
      (newTotal) =>
        setTotalItems((prev) => ({ ...prev, [identifier]: newTotal })),
      showErrorToast,
      removeEmptyValues(filters)
    );
  };

  const currentTotal =
    totalItems[`table_${tabIndex}`] ?? totalItems?.single_table ?? 0;

  const tableOptions: I2.DefaultTableProps['options'] = {
    rowStyle: (rowData: OrderModel) => {
      if (!rowData.order) return {};
      const hasChargeback = rowData.order.payments.some(
        (payment) => payment.status === 'chargeback'
      );
      return {
        backgroundColor: hasChargeback
          ? theme.colors.v2.yellow['100']
          : 'inherit',
      };
    },
  };

  return (
    <S.Main>
      <S2.Tabs onChange={(index: number) => setTabIndex(index)}>
        <S2.HeaderContainer>
          <Breadcrumb items={breadcrumb.items} />
          <S2.TitleContainer>
            <Title
              text={title}
              showBackButton={showBackButton}
              quantity={
                currentTotal === 10000 ? '1000+' : currentTotal.toString()
              }
            />

            <S2.ActionsContainer>
              {Array.isArray(table) && (
                <S2.TabList>
                  {table.map((tabTable, index) => {
                    const key = `actionsList_title_tab_${index}`;
                    const currentTotalItems = totalItems[`table_${index}`];
                    return (
                      <S2.Tab key={key}>
                        {tabTable.body.title}{' '}
                        {currentTotalItems !== undefined && (
                          <NotificationCircle text={currentTotalItems} />
                        )}
                      </S2.Tab>
                    );
                  })}
                </S2.TabList>
              )}

              {actionButton && (
                <Button
                  onClick={sendToCreate}
                  colorScheme={actionButton?.color ?? 'v2.brand'}
                  leftIcon={
                    <Icon as={actionButton?.icon ?? HiOutlinePlusCircle} />
                  }
                  p="13px 20px"
                  fontSize="medium"
                  borderRadius="large"
                >
                  {actionButton.text}
                </Button>
              )}
            </S2.ActionsContainer>
          </S2.TitleContainer>
        </S2.HeaderContainer>

        <S2.ChildrenContainer>{children}</S2.ChildrenContainer>

        {Array.isArray(table) ? (
          <S2.TabPanels>
            {table.map((tabTable, index) => {
              const key = `actionsList_panel_item_${index}`;
              return (
                <S2.TabPanel key={key}>
                  <DefaultTable
                    data={(query) =>
                      loadData(
                        query,
                        tabTable.fetch.apiCall,
                        tabTable.fetch.title,
                        `table_${index}`
                      )
                    }
                    {...tabTable.body}
                  />
                </S2.TabPanel>
              );
            })}
          </S2.TabPanels>
        ) : (
          <>
            <DefaultTable
              data={(query) =>
                loadData(
                  query,
                  table.fetch.apiCall,
                  table.fetch.title,
                  'single_table'
                )
              }
              {...table.body}
              options={tableOptions}
            />
          </>
        )}
      </S2.Tabs>
    </S.Main>
  );
}
