import { Flex, Text } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import LoadingSpinner from '../../../components/loadingSpinner';
import { GraphProps } from './interfaces';
import { BoxGraph } from './styles';
import { series, options } from './utils';

export const Graph = ({
  metrics,
  metricsInfo,
  loadingMetrics,
  title,
  isProdFilter,
  seriesLabel,
  type,
  section,
  optionsExtra,
}: GraphProps) => {
  if (!metrics || !metricsInfo || loadingMetrics)
    return (
      <BoxGraph>
        <LoadingSpinner />
      </BoxGraph>
    );

  return (
    <BoxGraph>
      <Flex justify="space-between" align="center">
        <Text>{section}</Text>
        <Text
          as="span"
          fontWeight="bold"
          fontSize="xxl"
          color={isProdFilter ? 'v2.brand.500' : 'blackAlpha.800'}
          {...(isProdFilter && { title: 'Média apenas dos dias produtivos' })}
        >
          {title}
        </Text>
      </Flex>

      <Chart
        options={options(metrics, optionsExtra)}
        series={series(metrics, seriesLabel)}
        type={type}
        height={160}
      />
    </BoxGraph>
  );
};

export default Graph;
