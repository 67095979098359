import { FieldValues, UnpackNestedValue } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as I from './interfaces';
import clone from '../../../utils/clone';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';

export const handleError = ({
  error,
  showToast,
  onSubmitClose,
  methods,
}: I.HandleErrorProps) => {
  onSubmitClose();
  const fieldRegex = /the field '([^']+)'/;

  const { reason } = JSON.parse(error.response ?? '');
  const match = clone(reason).match(fieldRegex);

  if (match) {
    const field = match[1];
    methods.setError(field, {
      type: 'manual',
      message: reason,
    });
  }

  showToast({
    title: 'Erro',
    description: `Não foi possível: ${reason}`,
    status: 'error',
  });
};

export const handleDescart = async ({
  methods,
  setIsLoaded,
  resetData,
  onDescartClose,
  loadInfo,
}: I.HandleDescartProps): Promise<void> => {
  setIsLoaded(false);
  loadInfo().then(() => {
    setTimeout(() => {
      methods.reset(resetData);
      onDescartClose();
      setIsLoaded(true);
    }, 300);
  });
};

export const handleSuccessResponse = ({
  res,
  isUpdate,
  showToast,
  setIsLoaded,
  methods,
  loadInfo,
  onSubmitClose,
  push,
  texts,
}: I.HandleSuccessResponseProps) => {
  const { created, updated, path } = texts;
  if ([200, 201, 204].includes(res.request.status)) {
    const successMessage = isUpdate ? updated : created;
    const message = formatConstants(
      constants.createOrUpdatedWithSucess,
      successMessage
    );
    toast.success(message);

    if (!isUpdate) {
      push(`${path}?id=${res.data.id}`);
    } else {
      setIsLoaded(false);
      loadInfo().then(() => {
        setTimeout(() => {
          methods.reset(res.data);
          setIsLoaded(true);
        }, 300);
      });
    }

    onSubmitClose();
  } else {
    handleError({
      error: res.request,
      showToast,
      methods,
      onSubmitClose,
    });
  }
};

export const handleSubmitAndCloseModal = <T extends FieldValues>({
  methods,
  submitFunction,
  onSubmitClose,
  additionalSuccessCheck = () => true,
}: I.HandleSubmitAndCloseModalProps<T>) => {
  return methods.handleSubmit(
    async (data: UnpackNestedValue<T>) => {
      const isSuccess = await submitFunction(data);
      if (
        !isSuccess ||
        (additionalSuccessCheck && !additionalSuccessCheck(data as T))
      ) {
        onSubmitClose();
      }
    },
    () => {
      onSubmitClose();
    }
  );
};
