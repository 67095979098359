import { useKeycloak } from '@react-keycloak/web';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Avatar, Box, IconButton, SimpleGrid } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { CiCalendarDate, CiRedo } from 'react-icons/ci';

import Title from '../../components/base/v2/title';
import LoadingSpinner from '../../components/loadingSpinner';
import InputDate from '../../components/base/v2/inputs/date';
import Graph from './graph';
import {
  formatDateToString,
  getPreparedMetrics,
  startDateValue,
  endDateValue,
  valueHandler,
  prodValues,
} from './utils';
import { useCharts } from '../../hooks/api/charts';
import {
  BoxLoading,
  EmailText,
  FilterBox,
  StyledSimpleGrid,
  GridGraphs,
  GroupCard,
  GroupFilter,
  GroupInfo,
} from './styles';
import GenericForm from '../../components/base/v2/genericForm';

import { UserModel } from '../../interfaces/user';
import { ChartsData } from '../../hooks/api/charts/interface';
import { MetricsModel } from './interfaces';

const Profile = () => {
  const { keycloak } = useKeycloak();
  const { getCharts } = useCharts();

  const [startDate, setStartDate] = useState(startDateValue);
  const [endDate, setEndDate] = useState(endDateValue);

  const [user, setUser] = useState<UserModel>();
  const [metrics, setMetrics] = useState<MetricsModel>();
  const [metricsAVGS, setMetricsAVGS] = useState<ChartsData>();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const [isProdFilter, setIsProdFilter] = useState(false);

  const toKeycloakProfile = (): void => {
    keycloak.accountManagement();
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    dispatchState: Dispatch<SetStateAction<Date>>
  ) => {
    setLoadingMetrics(true);
    const data = new Date(`${event.target.value}T00:00:00`);

    const newDate = new Date(
      data.getUTCFullYear(),
      data.getUTCMonth(),
      data.getUTCDate()
    );
    dispatchState(newDate);
  };

  const loadMetrics = async () => {
    try {
      const response = await getCharts(startDate, endDate);
      setMetricsAVGS(response.data.avgs);
      const loadedMetrics = getPreparedMetrics(response.data.buckets);
      setMetrics(loadedMetrics);
      setLoadingMetrics(false);
    } catch (error: unknown) {
      if (error instanceof Error) toast.error(error.message);
    }
  };

  const averageTimeValues = !isProdFilter
    ? metricsAVGS?.average_analysis_time || 0
    : prodValues(metrics?.averageAnalysisTime || [], true) * 60;

  const aprovalRateValues = !isProdFilter
    ? metricsAVGS?.approval_rate || 0
    : prodValues(metrics?.approvalRate || []);

  const finishedTransactionsValue = !isProdFilter
    ? metricsAVGS?.finished_transactions || 0
    : prodValues(metrics?.finishedTransactions || [], true);

  const chargebackValues = !isProdFilter
    ? metricsAVGS?.chargeback_rate || 0
    : prodValues(metrics?.chargebackRate || []);

  useEffect(() => {
    loadMetrics();
  }, [startDate, endDate]);

  useEffect(() => {
    const loadUserProfile = async () => {
      const response = await keycloak.loadUserProfile();
      setUser(response);
      setIsLoading(false);
    };
    loadUserProfile();
  }, []);

  if (isLoading) {
    return (
      <BoxLoading>
        <LoadingSpinner />
      </BoxLoading>
    );
  }

  return (
    <GenericForm
      title="Perfil"
      breadcrumb={[{ label: 'Início', href: '/' }, { label: 'Perfil' }]}
    >
      <StyledSimpleGrid columns={1} direction="row">
        <GridGraphs>
          <StyledSimpleGrid columns={1}>
            <FilterBox>
              <GroupInfo>
                <GroupCard>
                  <Avatar
                    size="md"
                    color="white"
                    name={`${user?.firstName} ${user?.lastName}`}
                  />
                  <Box>
                    <Title
                      text={`${user?.firstName} ${user?.lastName}`}
                      fontSize={22}
                    />
                    <EmailText>{user?.username}</EmailText>
                  </Box>

                  <IconButton
                    aria-label="Ir para o perfil"
                    icon={<FaExternalLinkAlt />}
                    onClick={toKeycloakProfile}
                  />
                </GroupCard>

                <IconButton
                  title="Apenas dias produtivos, com pelo menos 1 transação analisada"
                  aria-label="Apenas dias produtivos, com pelo menos 1 transação analisada"
                  icon={<CiCalendarDate />}
                  colorScheme={isProdFilter ? 'v2.brand' : 'gray'}
                  onClick={() => setIsProdFilter((isProdVal) => !isProdVal)}
                />
              </GroupInfo>
              <GroupFilter>
                <InputDate
                  label="Data inicial"
                  value={formatDateToString(startDate)}
                  onChange={(e) => handleDateChange(e, setStartDate)}
                />
                <InputDate
                  label="Data final"
                  value={formatDateToString(endDate)}
                  onChange={(e) => handleDateChange(e, setEndDate)}
                />
                <IconButton
                  aria-label="Atualizar dados"
                  icon={<CiRedo />}
                  colorScheme="gray"
                  onClick={async () => {
                    setLoadingMetrics(true);
                    await loadMetrics();
                  }}
                />
              </GroupFilter>
            </FilterBox>
          </StyledSimpleGrid>
          <SimpleGrid columns={2} spacing="md" w="100%">
            <Graph
              metrics={metrics}
              metricsInfo={metrics?.averageAnalysisTime}
              loadingMetrics={loadingMetrics}
              title={valueHandler(averageTimeValues, 'time')}
              isProdFilter={isProdFilter}
              type="area"
              section="Tempo médio de análise"
              optionsExtra
            />

            <Graph
              metrics={metrics}
              metricsInfo={metrics?.finishedTransactions}
              loadingMetrics={loadingMetrics}
              seriesLabel="Finalizados"
              title={valueHandler(finishedTransactionsValue, 'day')}
              isProdFilter={isProdFilter}
              type="bar"
              section="Finalizados"
              optionsExtra={false}
            />

            <Graph
              metrics={metrics}
              metricsInfo={metrics?.approvalRate}
              loadingMetrics={loadingMetrics}
              seriesLabel="Aprovados"
              title={valueHandler(aprovalRateValues, 'percent')}
              isProdFilter={isProdFilter}
              type="bar"
              section="Conversão"
              optionsExtra={false}
            />

            <Graph
              metrics={metrics}
              metricsInfo={metrics?.chargebackRate}
              loadingMetrics={loadingMetrics}
              seriesLabel="Chargeback"
              title={valueHandler(chargebackValues, 'percent')}
              isProdFilter={isProdFilter}
              type="area"
              section="Chargeback"
              optionsExtra={false}
            />
          </SimpleGrid>
        </GridGraphs>
      </StyledSimpleGrid>
    </GenericForm>
  );
};

export default Profile;
