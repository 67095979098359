/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlowCommentsFormModel } from '../../components/ordersInfo/tabInfo/commentsInfo/interface';
import {
  ConvertParametersProps,
  FlowModel,
  FluxModelForm,
  Parameters,
  ParametersForm,
  RuleModel,
} from '../../interfaces/flow';
import { BlocksModel } from '../../interfaces/blocks';

export const handleDataToForm = (values: FlowModel) => {
  values?.nodes.forEach((node) => {
    if (!node.action) {
      node.action = 'delegar';
    }
  });

  return values;
};

export const handleCommentsFormToModel = (
  data: FlowCommentsFormModel,
  flow: FlowModel
) => {
  flow.comment = {
    title: data.title,
    description: data.message,
  };

  return flow;
};

const toBoolean = (value: boolean | string): boolean => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }
  return value;
};

const toBooleanAndNull = (value: boolean | string | null): boolean | null => {
  if (typeof value === 'string') {
    return JSON.parse(value.toLowerCase());
  }
  return value;
};

const removeIrrelevantParameters = (
  parameters: ParametersForm,
  validParameterNames: string[]
) => {
  Object.keys(parameters).forEach((key) => {
    if (!validParameterNames?.includes(key)) {
      delete parameters[key];
    }
  });
};

const convertParameterValue = (value: any, type: string) => {
  if (
    Array.isArray(value) &&
    ['array of strings', 'array of ints'].includes(type)
  ) {
    return value.map((item) => item);
  }

  if (['int', 'float'].includes(type)) {
    return value ?? 0;
  }

  if (type === 'boolean') {
    return toBooleanAndNull(value);
  }

  if (type === 'duration') {
    return new Date(value).toISOString();
  }

  return value;
};

const ConvertParameters = ({
  ruleName,
  parameters,
  rules,
}: ConvertParametersProps) => {
  if (!parameters) return undefined;

  const rule = rules.find((item) => item.name === ruleName);
  if (!rule) return undefined;

  const validParameterNames = rule.parameters?.map((param) => param.name);
  removeIrrelevantParameters(parameters, validParameterNames);

  return Object.entries(parameters).reduce((acc, [key, value]) => {
    const parameter = rule.parameters?.find((item) => item.name === key);

    if (parameter) {
      acc[key] = convertParameterValue(value, parameter.type);
    }

    return acc;
  }, {} as Parameters);
};

export const handleFormDataToModel = (
  rules: RuleModel[],
  form: FluxModelForm
): FlowModel | BlocksModel => {
  form?.nodes.forEach((node) => {
    if (node.action === 'delegar') {
      delete node.action;
    } else {
      delete node.relay_to;
    }
  });
  const comment =
    form?.title && form?.message
      ? {
          title: form.title,
          description: form.message,
        }
      : null;
  delete form?.title;
  delete form?.message;

  return {
    ...form,
    comment,
    allow_review: toBoolean(form.allow_review),
    nodes: form.nodes.map((node) => {
      const newNode = {
        ...node,
        terms: node.terms.map((term) => ({
          ...term,
          complement: toBoolean(term.complement),
          rule: {
            name: term.rule.name,
            parameters: ConvertParameters({
              ruleName: term.rule.name,
              parameters: term.rule.parameters,
              rules,
            }),
          },
        })),
      };

      return newNode;
    }),
  };
};
