import React from 'react';
import { Stack } from '@chakra-ui/react';

import * as S from '../styles/main';
import * as I from './interfaces';

import Breadcrumb from '../components/base/v2/breadcrumb';
import Title from '../components/base/v2/title';
import Button from '../components/base/v2/button';

export default function GenericFormPage({
  breadcrumb,
  title,
  children,
  actions,
}: I.GenericPageFormProps): React.ReactElement {
  return (
    <S.Main>
      <Stack spacing="medium" pt=".5rem" pb="2.5rem">
        <Breadcrumb items={breadcrumb.items} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title
            showBackButton={title.showBackButton ?? false}
            backButtonUrl={title.backButtonUrl}
            text={title.text}
          />

          <Stack direction="row" spacing="medium">
            {actions
              ? actions.map((item, index) => {
                  const key = `genericForm_actions_${item.text}-${index}`;

                  return (
                    <Button
                      key={key}
                      onClick={item.onClick}
                      colorScheme={item.color ?? 'primary'}
                      leftIcon={item.icon}
                      variant={item.variant ?? 'solid'}
                      p="13px 20px"
                      fontSize="medium"
                      borderRadius="large"
                    >
                      {item.text}
                    </Button>
                  );
                })
              : null}
          </Stack>
        </Stack>
      </Stack>

      {children}
    </S.Main>
  );
}
